import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'
import router from "@/router";

const baseURL = "/alarms";

export default ({
    namespaced: true,
    state: {
        alarms: [],
        alarms_archived: [],
        recipients: [],
        logs: [],
        item: {},
        status: 'loading'
    },
    actions: {
        async index({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, { params: { ...payload } });
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

       

        async indexArchive({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_archieved=true`, { params: { ...payload } });
            commit('SET_ITEMS_ARCHIVED', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async store({ dispatch }: { dispatch: Dispatch }, payload = {}) {
            const { data } = await axios.post(`${baseURL}`, payload);
            router.push('/alarms')
            return data
        },

        async update({ dispatch }: { dispatch: Dispatch }, payload = { id: '' }) {
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            return data
        },

        async show({ commit }: { commit: Commit }, id = "") {
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async show_recipient({ commit }: { commit: Commit }, id = "") {
            const { data } = await axios.get(`${baseURL}/recipient/${id}`);
            return data
        },
        async destroy({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async archive({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.delete(`${baseURL}/${id}`);
            router.push('/alarms')
            return data
        },

        async restore({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.post(`${baseURL}/${id}/restore`);
            router.push('/alarms')
            return data
        },

        async recipients({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            // const { data } = await axios.get(`${baseURL}`, { params: { ...payload } });
            const { data } = await axios.get(`${baseURL}/recipients`, { params: { ...payload } });
            commit('SET_RECIPIENTS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

          async logs({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            // const { data } = await axios.get(`${baseURL}`, { params: { ...payload } });
            const { data } = await axios.get(`${baseURL}/alarmLogs`, { params: { ...payload } });
            commit('SET_LOGS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async save_recipients({ dispatch }: { dispatch: Dispatch }, payload = {}) {
            const { data } = await axios.post(`${baseURL}/save-recipients`, payload);
            router.push('/dmz_recipients')
            return data
        },


    },
    mutations: {
        SET_ITEMS(state: any, data: any) {
            state.alarms = data.data;
        },
        SET_RECIPIENTS(state: any, data: any) {
            state.recipients = data.data;
        },
        SET_ITEMS_ARCHIVED(state: any, data: any) {
            state.alarms_archived = data.data;
        },
        SET_LOGS(state: any, data: any) {
            state.logs = data.data;
        },
        SET_STATUS(state: any, payload: string) {
            state.status = payload;
        },
    },
    getters: {
        items(state: any) {
            return state.alarms;
        },
        recipients(state: any, data: any) {
            return state.recipients;
        },
        items_archived(state: any) {
            return state.alarms_archived;
        },
        logs(state: any) {
            return state.logs;
        },
        status(state: any) {
            return state.status;
        }
    }
});




