import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'
import router from "@/router";

const baseURL = "/outages";

export default ({
    namespaced: true,
    state: {
        outages: [],
        outages_archived: [],
        planned_outages_archived: [],
        outages_by_status: [],
        outages_by_statusplanned: [],
        outages_by_gomp: [],
        outages_by_gomp14days: [],
        item: {},
        status: 'loading'
    },
    actions: {
        async index({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, { params: { ...payload } });
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexArchive({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_archieved=true`, { params: { ...payload } });
            commit('SET_ITEMS_ARCHIVED', data)
            console.log("unplaplanned",data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexPlannedArchive({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_archieved=true`, { params: { ...payload } });
            commit('SET_PLANNED_ITEMS_ARCHIVED', data)
            console.log("planned",data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexByStatus({ commit }: { commit: Commit }, payload = { status: "" }) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/status/${payload.status}`, { params: { ...payload } });
            commit('SET_ITEMS_STATUS', data)
            commit('SET_STATUS', 'completed')

        },
        async indexByStatusPLanned({ commit }: { commit: Commit }, payload = { status: "" }) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/statusplanned/${payload.status}`, { params: { ...payload } });
            commit('SET_ITEMS_STATUSPlanned', data)
            commit('SET_STATUS', 'completed')

        },

        async indexByGomp({ commit }: { commit: Commit }, payload = { status: "" }) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/gomp_status/${payload.status}`, { params: { ...payload } });
            commit('SET_ITEMS_GOMP', data)
            commit('SET_STATUS', 'completed')

        },
        async indexByGomp14days({ commit }: { commit: Commit }, payload = { status: "" }) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/gomp14days_status/${payload.status}`, { params: { ...payload } });
            commit('SET_ITEMS_GOMP14DAYS', data)
            commit('SET_STATUS', 'completed')

        },
        async store({ dispatch }: { dispatch: Dispatch }, payload = {}) {
            const { data } = await axios.post(`${baseURL}`, payload);
            // router.push('/outage-logs')
            return data
        },

        async sendSmsRaw({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-sms-raw`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async gomp({ dispatch }: { dispatch: Dispatch }, payload = { data: [] }) {
            const { data } = await axios.post(`${baseURL}/gomp`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendClosedSmsRaw({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-closed-sms-raw`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendSmsGroup({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-sms-group`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendClosedSmsGroup({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-closed-sms-group`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendEmailOer({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-email-oer`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendOerEmailGroup({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-oer-email-group`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendSerEmailGroup({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-ser-email-group`, payload.data);
            // router.push('/outage-logs')
            return data
        },

        async sendEmailSer({ dispatch }: { dispatch: Dispatch }, payload = { id: "", data: [] }) {
            const { data } = await axios.post(`${baseURL}/${payload.id}/send-email-ser`, payload.data);
            router.push('/outage-logs')
            return data
        },

        async update({ dispatch }: { dispatch: Dispatch }, payload = { id: '' }) {
            const { data } = await axios.post(`${baseURL}/update`, payload);
            // router.go(0)
            return data
        },

        async show({ commit }: { commit: Commit }, id = "") {
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async destroy({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async archive({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.delete(`${baseURL}/${id}`);
            if(data.data.is_planned == 1){
                router.push('/planned-outage-logs')
            }else{
                router.push('/outage-logs')
            }
            return data
        },

        async restore({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.post(`${baseURL}/${id}/restore`);
            if(data.data.is_planned == 1){
                router.push('/planned-outage-logs')
            }else{
                router.push('/outage-logs')
            }
            return data
        },

        async showOutagePlants({ commit }: { commit: Commit }) {
            const { data } = await axios.get(`${baseURL}/plants`);
            return data
        },
        async getDMZrecipient({ commit }: { commit: Commit }) {
            const { data } = await axios.get(`${baseURL}/signatories`);
            return data
        },

        async getAllusers({ commit }: { commit: Commit }) {
            const { data } = await axios.get(`${baseURL}/allusers`);
            return data
        },

        async showWesm({ commit }: { commit: Commit }) {
            const { data } = await axios.get(`${baseURL}/wesm`);
            return data
        },

        async showWER({ dispatch }: { dispatch: Dispatch }, payload = { data: [] }) {
            const { data } = await axios.post(`${baseURL}/WER`, payload.data);
            // router.push('/outage-logs')
            return data
        },


        async showOutageTemplates({ dispatch }: { dispatch: Dispatch }, payload = { plant_id: "" }) {
            const { data } = await axios.get(`${baseURL}/${payload.plant_id}/templates`);
            return data
        },
        async showOutageTemplatesLocation({ dispatch }: { dispatch: Dispatch }, payload = { plant_id: "" }) {
            const { data } = await axios.get(`${baseURL}/${payload.plant_id}/templates_locations`);
            return data
        },

        async downloadOer({ commit }: { commit: Commit }, id = "") {
            const { data } = await axios.get(`${baseURL}/oer`);
            return data
        },

    },
    mutations: {
        SET_ITEMS(state: any, data: any) {
            state.outages = data.data;
        },
        SET_ITEMS_ARCHIVED(state: any, data: any) {
            state.outages_archived = data.data;
        },
        SET_PLANNED_ITEMS_ARCHIVED(state: any, data: any) {
            state.planned_outages_archived = data.data;
        },
        SET_ITEMS_STATUS(state: any, data: any) {
            state.outages_by_status = data.data;
        },
        SET_ITEMS_STATUSPlanned(state: any, data: any) {
            state.outages_by_statusplanned = data.data;
        },
        SET_ITEMS_GOMP(state: any, data: any) {
            state.outages_by_gomp = data.data;
        },
        SET_ITEMS_GOMP14DAYS(state: any, data: any) {
            state.outages_by_gomp14days = data.data;
        },
        SET_STATUS(state: any, payload: string) {
            state.status = payload;
        },
    },
    getters: {
        items(state: any) {
            return state.outages;
        },
        items_archived(state: any) {
            return state.outages_archived;
        },
        planned_items_archived(state: any) {
            return state.planned_outages_archived;
        },
        outages_by_status(state: any) {
            return state.outages_by_status;
        },
        outages_by_statusplanned(state: any) {
            return state.outages_by_statusplanned;
        },
        outages_by_gomp(state: any) {
            return state.outages_by_gomp;
        },
        outages_by_gomp14days(state: any) {
            return state.outages_by_gomp14days;
        },
        status(state: any) {
            return state.status;
        }
    }
});




